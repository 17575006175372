@import './breakpoints.scss';
@import './typography.scss';
@import './variables.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'TWKLausanne-300', -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

body {
  padding-top: 6.667vw;
  background-color: $light-grey;
  @media (max-width: $breakpoint-md) {
    padding-top: 96px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

ul {
  padding: 0;
  margin: 0;
  li {
    padding: 0;
    list-style-type: none;
  }
}
h1,
h2,
h3,
p {
  margin: 0;
}
b,
strong {
  font-family: 'TWKLausanne-700';
}

.carouselPartners {
  &.slick-slider {
    position: unset;
  }
  .slick-track {
    @media (max-width: $breakpoint-md) {
      display: flex;
      align-items: center;
    }
  }
  .slick-dots {
    bottom: 7.2vw;
    @media (max-width: $breakpoint-md) {
    }
    li {
      width: 1.111vw;
      height: 0.556vw;
      margin: 0.278vw;
      @media (max-width: $breakpoint-md) {
        width: 16px;
        height: 8px;
        margin: 8px 4px;
      }
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0.833vw;
        background-color: $hover_grey;
        @media (max-width: $breakpoint-md) {
          border-radius: 12px;
        }
        &::before {
          display: none;
        }
      }
      &.slick-active {
        width: 2.222vw;
        @media (max-width: $breakpoint-md) {
          width: 32px;
        }
        button {
          background-color: $discharging;
        }
      }
    }
  }
  button {
    &.slick-arrow {
      display: none;
    }
  }
}

.carouselFeatures {
  &.slick-slider {
    position: unset;
    width: 100%;
    touch-action: auto;
    -ms-touch-action: auto;
    scroll-snap-type: x mandatory;
  }
  .slick-track {
    display: flex;
    .slick-slide {
      height: inherit;
      div {
        height: 100%;
      }
    }
  }
  .slick-dots {
    bottom: 5vw;
    margin-left: -6.111vw;
    @media (max-width: $breakpoint-md) {
      bottom: 72px;
      margin-left: -16px;
    }
    li {
      width: 1.111vw;
      height: 0.556vw;
      margin: 0.278vw;
      z-index: 5;
      @media (max-width: $breakpoint-md) {
        width: 16px;
        height: 8px;
        margin: 8px 4px;
      }
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0.833vw;
        background-color: $white_30;
        @media (max-width: $breakpoint-md) {
          border-radius: 12px;
        }
        &::before {
          display: none;
        }
      }
      &.slick-active {
        width: 2.222vw;
        @media (max-width: $breakpoint-md) {
          width: 32px;
        }
        button {
          background-color: $white;
        }
      }
    }
  }
}

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-loader-path: './' !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -25px;
  [dir='rtl'] & {
    left: auto;
    right: -25px;
  }
  &:before {
    content: $slick-prev-character;
    [dir='rtl'] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -25px;
  [dir='rtl'] & {
    left: -25px;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir='rtl'] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

.block-img img {
  transform: none !important;
  width: auto !important;
}